import React from "react"
import { useState, useEffect } from "react"
import { Collapse } from "react-collapse"
import { useStaticQuery, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import ImgSeqComp from "../careersPage/ImgSeqComp"

const WhoWeAre = () => {
  const [isVisible, setIsVisible] = useState({})
  const { language } = useI18next()

  //querying data from strapi
  const response = useStaticQuery(graphql`
    query valuesQuery {
      strapiData: allStrapiCareersPage {
        strapiData {
          data {
            attributes {
              localizations {
                data {
                  attributes {
                    values {
                      id
                      description
                      image {
                        data {
                          id
                          attributes {
                            name
                            url
                          }
                        }
                      }
                      sequence_fps
                      sequence_length
                      title
                    }
                    values_header
                  }
                }
              }
              values {
                id
                description
                sequence_fps
                sequence_length
                title
                image {
                  data {
                    id
                    attributes {
                      url
                      name
                    }
                  }
                }
              }
              values_header
            }
          }
        }
      }
    }
  `)
  const data = response.strapiData.strapiData.data.attributes
  const frStrapiData =
    response.strapiData.strapiData.data.attributes.localizations.data[0]
      .attributes

  const [langData, setLangData] = useState(data)
  const handleClick = id => {
    setIsVisible(prevState => {
      return { ...prevState, [id]: !prevState[id] }
    })
  }
  //set the text depending on the locale
  useEffect(() => {
    language === "en" ? setLangData(data) : setLangData(frStrapiData)
  }, [langData])

  return (
    <>
      <div className="flex self-center h-screen justify-center items-center">
        {/* styling for copy */}
        <div className="flex flex-col min-h-[50%] p-6 justify-center  items-center z-30">
          {/* container */}
          <div className="my-6 flex flex-col justify-center items-center  text-center z-30">
            <h1 className="pb-12 text-3xl sm:text-6xl">
              {language === "en" ? "Who we are" : "À propos de nous"}
            </h1>

            {/* section for array of values */}
            <div className="flex flex-wrap justify-center items-center">
              {langData.values.map(value => {
                return (
                  <div
                    onClick={() => handleClick(value.id)}
                    className="flex flex-col justify-center items-center my-8"
                    key={value.id}
                  >
                    <ImgSeqComp
                      fps={value.sequence_fps}
                      seqLength={value.sequence_length}
                      path={
                        language === "en" ? value.image.data : value.image.data
                      }
                      title={value.title}
                      imgAlt={`${value.title} animated graphic`}
                    />
                    <div className="w-[160px] sm:w-[200px] px-2">
                      <Collapse isOpened={isVisible[value.id]}>
                        <hr className="py-2" />
                        <p className="text-left sm:h-[200px] h-[100px] text-sm sm:text-lg leading-4 break-words tracking-normal transition duration-500 ease-in-out;">
                          {value.description}
                        </p>
                      </Collapse>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoWeAre
