import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Link } from "gatsby"
import SlugifyString from "../util/SlugifyString.js"
import { useI18next } from "gatsby-plugin-react-i18next"

const JobsDisplayed = ({ jobs }) => {
  const { language } = useI18next()
  return (
    <div className="mt-4 self-center w-[90%] sm:w-[85%] md:w-[65%]">
      {/* depending on if there are jobs avilable display text or display the jobs */}
      {jobs.length == 0 || jobs == undefined ? (
        <h4 className="text-lg pt-16">no openings available at the moment.</h4>
      ) : (
        jobs.map(job => {
          const matchedTitle = job.title.match(/(?<en>[^/]+?)\s*\/\s*(?<fr>.+)/)
          const en_title = matchedTitle.groups.en
          const fr_title = matchedTitle.groups.fr
          const title = language === "en" ? en_title : fr_title

          const slugifiedTitle = SlugifyString(job.title)
          // console.log(slugifiedTitle)
          return (
            <div key={uuidv4()} className="flex justify-center m-4">
              <div className="bg-[#b3aed3] justify-center rounded-[30px] py-2 px-6 mt-2 flex w-full flex-col  h-[62.5px] JDBox-shadow">
                <Link className="sm:mx-2" to={`/careers/${slugifiedTitle}`}>
                  <div className="flex justify-between text-[13px] sm:text-base md:text-lg items-center">
                    <h4 className="mr-2">{title}</h4>
                    <div className="self-center flex flex-col items-center">
                      <span className="material-symbols-rounded">
                        open_in_new
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

export default JobsDisplayed
