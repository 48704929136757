import React from "react"
import { Link } from "react-scroll"

const AnchorArrow = ({ destination }) => {
  return (
    <div className="flex justify-center w-screen absolute bottom-[18px] z-50">
      <Link
        to={destination}
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        href={destination}
      >
        <span className="material-symbols-rounded text-[36px] cursor-pointer text-slate-200 animate-pulse hover:animate-bounce">
          expand_more
        </span>
      </Link>
    </div>
  )
}

export default AnchorArrow
