import { useI18next } from "gatsby-plugin-react-i18next"
import React, { useEffect, useRef, useState } from "react"

const ImgSeqComp = ({ path, seqLength, fps, title, imgAlt }) => {
  const [count, setCount] = useState(0)
  const [sortedURLs, setSortedURLs] = useState([])
  const { language } = useI18next()

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef()
  const previousTimeRef = useRef()
  const playFlag = useRef()
  const cursorFlag = useRef()

  const animate = time => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current
      if (playFlag.current) {
        // Pass on a function to the setter of the state
        // to make sure we always have the latest state
        setCount(prevCount => (prevCount + deltaTime * fps * 0.001) % seqLength)
      }
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, []) // Make sure the effect runs only once

  const handleMouseEnter = () => {
    playFlag.current = true
    cursorFlag.current = true
  }
  const handleMouseLeave = () => {
    cursorFlag.current = false
  }
  const visibleFrame = Math.floor(count)

  useEffect(() => {
    if (visibleFrame == 0) {
      playFlag.current = cursorFlag.current
    }
  }, [visibleFrame])

  //on mount - iterate through path Array to sort numerically
  useEffect(() => {
    language === "en"
      ? path.forEach(img => {
          let number = img.attributes.name.match(/(\d+)/)
          img.order = number[0]
          const numAscending = [...path].sort((a, b) => a.order - b.order)
          setSortedURLs(numAscending)
        })
      : path.forEach(img => {
          let number = img.attributes.name.match(/(\d+)/)
          img.attributes.order = number[0]
          const numAscending = [...path].sort(
            (a, b) => a.attributes.order - b.attributes.order
          )
          setSortedURLs(numAscending)
        })
  }, [])

  return (
    <div
      className="flex flex-col items-center justify-end p-5 mx-[10px] rounded-[10px] w-[100px] sm:w-[200px] h-[100px] sm:h-[224px]"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {sortedURLs.map((img, index) => {
        const opacity = index == visibleFrame ? 1 : 0
        const position = index == visibleFrame ? "relative" : "absolute"
        return (
          <img
            src={language === "en" ? img.attributes.url : img.attributes.url}
            style={{ opacity: opacity, position: position }}
            alt={imgAlt}
            key={img.attributes.name}
            width="100"
            height="100"
          />
        )
      })}

      <h4>{title}</h4>
    </div>
  )
}
export default ImgSeqComp
