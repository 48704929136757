import React from "react"

const TitleText = ({ data, bodyText }) => {
  //had to specify exact location of data until strapi components are able to show up in api response
  return (
    <>
      <div className="flex z-30 self-center w-screen justify-center items-center h-[calc(100vh-50px)]">
        {/* styling for copy */}
        <div className="flex flex-col w-screen p-6 justify-center items-center z-30 min-h-[50%]">
          {/* section container for copy */}
          <div className="flex flex-col justify-center items-center text-center z-30 ">
            <h1 className="pb-12 px-6 text-3xl sm:text-6xl leading-[1.2]">
              {data.mission_title}
            </h1>
            <h3 className="text-xl sm:text-2xl mt-4 ">
              <em>{data.mission_tagline}</em>
            </h3>
            <p className="px-6 text-center py-4 max-w-[35em] leading-relaxed">
              {bodyText}
            </p>
            <p>
              <em>{data.signoff}</em>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TitleText
