import React from "react"
const BgVideo = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className=" absolute  h-[calc(100vh-50px)]">
    <video
      autoPlay
      loop
      muted
      className="mask2 h-[calc(100vh-50px)] w-screen object-cover opacity-75 brightness-75 contrast-125 saturate-150 "
    >
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  </div>
)
export default BgVideo
