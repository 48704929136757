import React, { useState, useEffect } from "react"

const ScrollToTopBtn = () => {
  const [showScroll, setShowScroll] = useState(false)

  //called when component mounts
  useEffect(function setUpListener() {
    //on scroll sets showScroll state then conditionally styles the arrowUp
    const updateState = () => {
      //measures distance scrolled down from top of window
      let scrollY = window.pageYOffset

      if (!showScroll && scrollY > 400) {
        setShowScroll(true)
      } else if (showScroll && scrollY <= 400) {
        setShowScroll(false)
      }
    }
    window.addEventListener("scroll", updateState)

    //cleans up the event listener when the component is unmounted
    return function cleanUpListener() {
      window.removeEventListener("scroll", updateState)
    }
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div>
      <div
        className="fixed bottom-[20px] right-[20px] z-50"
        onClick={scrollToTop}
        onKeyDown={scrollToTop}
        role="link"
        aria-label="scroll"
        tabIndex={0}
        style={showScroll === true ? { display: "block" } : { display: "none" }}
      >
        <span className="material-symbols-rounded text-[2rem] animate-pulse cursor-pointer text-slate-600 hover:animate-bounce ">
          expand_less
        </span>
      </div>
    </div>
  )
}
export default ScrollToTopBtn
