import React, { useEffect, useState } from "react"
import JobsDisplayed from "./JobsDisplayed"

const SearchBar = ({ placeholder, jobs }) => {
  const [filteredJobs, setFilteredJobs] = useState([])
  const [wordEntered, setWordEntered] = useState("")
  const [filteredJobsAvail, setFilteredJobsAvail] = useState(true)

  const handleFilter = ev => {
    const searchWord = ev.target.value
    setWordEntered(searchWord)
    const newFilter = jobs.filter(value => {
      return (
        value.title.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.employment_type
          .toLowerCase()
          .includes(searchWord.toLowerCase()) ||
        value.experience.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.industry.toLowerCase().includes(searchWord.toLowerCase())
      )
    })
    if (searchWord === "") {
      setFilteredJobs([])
    } else {
      setFilteredJobs(newFilter)
    }
  }

  const clearInput = () => {
    setFilteredJobs([])
    setWordEntered("")
  }

  useEffect(() => {
    if (wordEntered !== "" && filteredJobs.length === 0) {
      setFilteredJobsAvail(false)
    } else {
      setFilteredJobsAvail(true)
    }
  }, [wordEntered])

  const isDisplayed = jobs.length <= 0 || jobs == undefined ? "none" : "block"
  const isVisible =
    (filteredJobs.length == 0 || filteredJobs == undefined) &&
    filteredJobsAvail === true
      ? "hidden"
      : "visible"

  return (
    // search container
    <div className="flex-col mt-[3em]" style={{ display: isDisplayed }}>
      {/* search input */}
      <h4 className="text-slate-100 text-center text-lg sm:text-2xl">
        {placeholder}
      </h4>
      <div className=" mb-[20px] text-slate-100 flex justify-center">
        <input
          className="w-[30%] px-4 mt-6 py-2 text-base border-b-2 placeholder-slate-200 bg-transparent border-gray-100 focus:outline-none focus:border-b-blue-400"
          type="text"
          // placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
          aria-label="Search"
        />
        {/* search icon */}
        <div className=" w-[50px] flex justify-end align-bottom">
          {wordEntered === "" ? (
            <button className="material-icons-round text-slate-100 py-2 mt-6">
              search
            </button>
          ) : (
            <button
              className="material-icons-round text-slate-100 py-2 w-8 hover:cursor-pointer"
              onClick={clearInput}
            >
              close
            </button>
          )}
        </div>
      </div>

      {/* result area */}

      <div className="flex flex-col justify-center z-40 pb-4">
        <h4
          className="m-2 text-center text-slate-500"
          style={{ visibility: isVisible }}
        >
          {filteredJobsAvail === false
            ? "Sorry, no jobs match your search. Try looking below!"
            : filteredJobs.length === 1
            ? filteredJobs.length + " result"
            : filteredJobs.length + " results"}
        </h4>

        <JobsDisplayed jobs={filteredJobs.length === 0 ? jobs : filteredJobs} />
      </div>
    </div>
  )
}

export default SearchBar
