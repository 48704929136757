import React from "react"
import Layout from "../../components/layout/Layout"
import { graphql } from "gatsby"
import Values from "../../components/careersPage/Values"
import BgVideo from "../../components/util/BgVideo"
import TitleText from "../../components/careersPage/TitleAndText"
import SearchBar from "../../components/careersPage/SearchBar"
import SignOffText from "../../components/careersPage/SignOffText"
import ScrollToTopBtn from "../../components/layout/ScrollToTopBtn"
import { useI18next } from "gatsby-plugin-react-i18next"
import Seo from "../../components/util/Seo"
// import JobsDisplayed from "../../components/careersPage/JobsDisplayed"
import AnchorArrow from "../../components/util/AnchorArrow"

const Careers = ({ data }) => {
  const strapiData = data.allStrapiCareersPage.strapiData.data.attributes
  const frStrapiData =
    data.allStrapiCareersPage.strapiData.data.attributes.localizations.data[0]
      .attributes
  const { language } = useI18next()

  const jobOpenings = data.recootyData.nodes[0]?.jobs

  const isJobAvail =
    jobOpenings?.length == 0 || jobOpenings == undefined || jobOpenings == null
      ? false
      : true

  return (
    <Layout>
      <ScrollToTopBtn />
      <div className="bgBlackToWhiteGradient ">
        {/* video */}
        <BgVideo
          videoSrcURL={strapiData.bg_video.data.attributes.url}
          videoTitle="darkwing video"
        />
        <AnchorArrow destination="who-we-are" />

        {/* Mission statement */}
        <TitleText
          data={language === "en" ? strapiData : frStrapiData}
          bodyText={
            language === "en"
              ? strapiData.mission_text
              : frStrapiData.mission_text
          }
        />

        {/* multi-color background section */}
        <div className="relative ">
          <div className="mask1 absolute left-0 right-0 bottom-0 top-0 "></div>
          {/* Values section */}
          <div className="relative h-screen">
            <div id="who-we-are">
              <Values />
              {/* <AnchorArrow destination="explore-roles" /> */}
            </div>
          </div>

          {/* Job openings section */}
          {jobOpenings.length == !0 ? (
            <div
              id="explore-roles"
              className="relative h-[1200px] flex flex-col "
            >
              <h1 className="text-center mb-14 text-3xl sm:text-6xl">
                {language === "en"
                  ? strapiData.call_to_action
                  : frStrapiData.call_to_action}
              </h1>
              <SearchBar
                placeholder={
                  language === "en"
                    ? "tell us what you're looking for.."
                    : frStrapiData.search_bar_placeholder
                }
                jobs={jobOpenings}
              />
              {/* removed the carousel for now until we are able to recieve the department key from recooty */}
              {/* <Carousel /> */}
              {/* <JobsDisplayed jobs={jobOpenings} /> */}
            </div>
          ) : (
            <div className="relative flex flex-col justify-start text-center sm:text-[24px] h-[550px]">
              <h1 className="text-center mb-14 text-3xl sm:text-6xl">
                {language === "en"
                  ? strapiData.call_to_action
                  : frStrapiData.call_to_action}
              </h1>
              <p>Nothing available at the moment. Check back soon!</p>
            </div>
          )}
        </div>

        <SignOffText jobsAvail={isJobAvail} />

        {/* Will add these elements below once we have the visual content for this area */}
        {/* <div id="environment" className="text-center mb-40">
          <SeeEnvironment />
          <MeetEmployees />
        </div> */}
      </div>
    </Layout>
  )
}

export default Careers

export const Head = () => (
  <Seo
    title="Join Our Growing Team | Haply"
    description="Haply is looking for talented and determined people to build technology that will change the world forever."
  />
)
export const query = graphql`
  query MyQuery {
    allStrapiCareersPage {
      strapiData {
        data {
          attributes {
            call_to_action
            mission_tagline
            bg_video {
              data {
                attributes {
                  url
                }
              }
            }
            localizations {
              data {
                attributes {
                  call_to_action
                  mission_tagline
                  mission_text
                  mission_title
                  search_bar_placeholder
                  values {
                    image {
                      data {
                        attributes {
                          url
                          name
                        }
                      }
                    }
                    description
                    sequence_fps
                    sequence_length
                    title
                  }
                  values_header
                }
              }
            }
            mission_text
            mission_title
            search_bar_placeholder
            values {
              image {
                data {
                  attributes {
                    url
                    name
                  }
                }
              }
              description
              sequence_fps
              sequence_length
              title
            }
            values_header
          }
        }
      }
    }
    recootyData: allRecootyApiApiV1Jobs66B382D629D1F3Fdb55651Fced8126A4 {
      nodes {
        job_count
        jobs {
          apply_url_external
          description
          employment_type
          experience
          industry
          remote
          title
        }
      }
    }
  }
`
