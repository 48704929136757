import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"

const SignOffText = ({ jobsAvail }) => {
  const { language } = useI18next()
  //strapi query
  const response = useStaticQuery(graphql`
    query SignOffText {
      strapiData: allStrapiCareersPage {
        strapiData {
          data {
            attributes {
              send_mail_image {
                data {
                  attributes {
                    url
                  }
                }
              }
              sign_off
              sign_off_2
              sign_off_bold
              localizations {
                data {
                  attributes {
                    sign_off
                    sign_off_2
                    sign_off_bold
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  //shorthand for easier to read code
  const data = response.strapiData.strapiData.data.attributes

  // image url
  const imgUrl = data.send_mail_image.data.attributes.url

  //data in french from strapi
  const frData =
    response.strapiData.strapiData.data.attributes.localizations.data[0]
      .attributes

  return (
    <div className="flex justify-center my-20 ">
      <div className="flex-col text-center py-8 text-[11px] sm:text-base">
        {/* depending on if there are jobs available display different texts */}
        {jobsAvail == true ? (
          <p className=" text-slate-400 py-1">
            {language === "en"
              ? "Don’t see anything that quite fits?"
              : "Vous ne trouvez rien qui corresponde à vos attentes ?"}
          </p>
        ) : (
          <p className=" text-slate-400 py-1">still interested?</p>
        )}
        <a
          href="https://haply.atlassian.net/servicedesk/customer/portal/3/group/4/create/35"
          className="text-slate-400 font-semibold py-1 hover:text-slate-800"
          target="_blank"
          rel="noreferrer"
        >
          {language === "en" ? data.sign_off_bold : frData.sign_off_bold}
        </a>
        <p className=" text-slate-400 py-1">
          {language === "en" ? data.sign_off_2 : frData.sign_off_2}
        </p>
      </div>
      <a
        href="https://haply.atlassian.net/servicedesk/customer/portal/3/group/4/create/35"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={imgUrl}
          alt="mail plane"
          className="object-contain ml-8 w-[100px] sm:w-[200px] brightness-90 animate-[wiggle_1s_ease-in-out_infinite]"
        />
      </a>
    </div>
  )
}

export default SignOffText
